import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GameResultsDialogComponent } from '../game-results-dialog/game-results-dialog.component';

@Component({
  selector: 'app-balloon-game',
  templateUrl: './balloon-game.component.html',
  styleUrls: ['./balloon-game.component.css'],
})
export class BalloonGameComponent implements OnInit {
  gameArea!: any;
  showPopup: boolean = false;
  scoreDisplay!: any;
  startScreen!: any;
  resultsDiv!: any;
  score: number = 0;
  bubblesPopped: number = 0;
  reactionTimeTotal: number = 0;
  gameTimer: any;
  bubbleInterval: any;
  startTime: number = 0;
  endTime: number = 0;
  attentionMistakes: number = 0;
  decisionMakingPoints: number = 0;
  totalDecisions: number = 0;
  showpopup: boolean = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    // Initialize the game or start it based on user actions
    window.onload = () => {
      // Navigate to '/balloon-burst' on unload
      this.router.navigateByUrl('/balloon-burst');
    };
  }

  ngAfterViewInit() {
    this.gameArea = this.document.getElementById('gameArea');
    this.scoreDisplay = this.document.getElementById('score');
    console.log('alksflhasfl', this.scoreDisplay);
    this.startScreen = this.document.getElementById('startScreen');
    this.resultsDiv = this.document.getElementById('results');
  }

  ngOnDestroy() {
    // Clean up timers and intervals
    this.clearGame();
  }

  startGame(difficulty: 'easy' | 'hard') {
    console.log(this.startScreen);
    this.startScreen.style.display = 'none';
    this.gameArea.style.display = 'block';
    this.scoreDisplay.style.display = 'block';
    this.score = 0;
    this.bubblesPopped = 0;
    this.reactionTimeTotal = 0;
    this.attentionMistakes = 0;
    this.decisionMakingPoints = 0;
    this.totalDecisions = 0;
    this.scoreDisplay.textContent = '0';
    this.startTime = Date.now();
    let bubbleRate = difficulty === 'hard' ? 400 : 800;
    this.bubbleInterval = setInterval(() => this.addBubble(), bubbleRate);
    // Countdown from 1 to 20 and display in HTML
    let count = 20;
    let countdownDisplay = document.getElementById('countdown');

    if (countdownDisplay) {
      countdownDisplay.textContent = count.toString();
    } else {
      console.error('Countdown display element not found.');
    }

    let countdownTimer = setInterval(() => {
      count--;
      if (count <= 20) {
        if (countdownDisplay) {
          countdownDisplay.textContent = count.toString();
        }
      } else {
        clearInterval(countdownTimer);
        this.endGame();
      }
    }, 1000);

    this.gameTimer = setTimeout(() => this.endGame(), 20000); // Game duration is 20 seconds
  }

  addBubble() {
    const popSound = new Audio('/assets/happy-pop-2-185287.mp3');

    const bubble = document.createElement('div');
    bubble.classList.add('bubble');
    bubble.style.borderRadius = '50%';

    const curve = document.createElement('div');
    curve.classList.add('curve');
    bubble.appendChild(curve);

    bubble.style.position = 'absolute';
    bubble.style.cursor = 'pointer';
    bubble.style.opacity = '0.7';
    bubble.style.transition = 'transform 0.3s, opacity 0.3s;';
    bubble.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    curve.style.position = 'relative';
    curve.style.background =
      'linear-gradient(to right, transparent 50%, white 50%, white 100%)';

    curve.style.width = '10px';
    curve.style.height = '20px';
    curve.style.top = '10px';
    curve.style.left = '10px';
    curve.style.overflow = 'hidden';
    curve.style.borderRadius = '50%';
    curve.style.transform = 'rotate(215deg)';
    const size = Math.random() * 60 + 40;
    bubble.style.width = `${size}px`;
    bubble.style.height = `${size}px`;
    const colors = ['#FFC0CB', '#FFD700', '#FFA500', '#8A2BE2', '#1E90FF'];
    bubble.style.backgroundColor =
      colors[Math.floor(Math.random() * colors.length)];

    // Position the bubble
    const x = Math.random() * (this.gameArea.offsetWidth - size);
    const y = Math.random() * (this.gameArea.offsetHeight - size);

    bubble.style.left = `${x}px`;
    bubble.style.top = `${y}px`;
    this.gameArea.appendChild(bubble);
    const createdAt = Date.now();

    // Event listener for the bubble click
    bubble.addEventListener('click', () => {
      popSound.currentTime = 0; // Reset the audio playback position
      popSound.play();
      window.navigator.vibrate(500);
      if (Math.random() < 0.35) {
        // 35% chance for a special bubble
        const decisionType = Math.random() < 0.5 ? 'color' : 'text';

        if (decisionType === 'color') {
          bubble.style.backgroundColor =
            Math.random() < 0.5 ? '#FF0000' : '#00FF00'; // Red or green bubble
          this.handleDecisionBubble(bubble, decisionType);
          this.totalDecisions++;
        } else {
          // 'text' type
          const messages = ['Click Me!', "Don't Click!", 'Double Click!'];
          const message = messages[Math.floor(Math.random() * messages.length)];
          bubble.textContent = message;
          this.handleDecisionBubble(bubble, decisionType);
          this.totalDecisions++;
        }
      } else {
        // Regular bubble
        this.totalDecisions++;
        this.score++;
        this.bubblesPopped++;
        this.reactionTimeTotal += Date.now() - createdAt;
        this.scoreDisplay.textContent = `${this.score}`;

        bubble.remove();
      }
    });

    setTimeout(() => bubble.remove(), 2000);
  }

  // handleDecisionBubble(bubble: HTMLElement, decisionType: string) {
  //    if (decisionType === 'text' && bubble.textContent === "Don't Click!") {
  //      this.attentionMistakes++;
  //      bubble.remove();
  //    }
  // }

  handleDecisionBubble(bubble: HTMLElement, decisionType: string) {
    console.log("handleDecisionBubble")
    if (decisionType === 'color') {
      if (bubble.style.backgroundColor === '#FF0000') {
        this.decisionMakingPoints -= 1; // Penalize for clicking a red bubble
      } else if (bubble.style.backgroundColor === '#00FF00') {
        this.decisionMakingPoints += 2; // Reward for clicking a green bubble
      }
      bubble.remove();
    } else if (decisionType === 'text') {
      if (bubble.textContent === "Don't Click!") {
        // Do not increase or decrease points here since the bubble shouldn't be clicked
      } else if (bubble.textContent === 'Click Me!') {
        this.decisionMakingPoints += 1; // Reward for clicking the correct message
      } else if (bubble.textContent === 'Double Click!') {
        bubble.addEventListener('dblclick', () => {
          this.decisionMakingPoints += 3; // Reward for correct double-click
          bubble.remove();
        });
        return; // Do not remove yet - wait for possible double click
      }
      bubble.remove(); // Remove the bubble if it's not a double-click scenario
    }
  }

  endGame() {
    clearInterval(this.bubbleInterval);
    this.gameArea.style.display = 'none';
    this.scoreDisplay.style.display = 'none';
    this.endTime = Date.now();

    const playTime = (this.endTime - this.startTime) / 1000;
    const averageReactionTime = this.reactionTimeTotal / this.bubblesPopped;
    const reactionTimeScore =
      10 - Math.min(Math.max(averageReactionTime / 200, 1), 10);
    const handEyeCoordinationScore = Math.min(this.bubblesPopped / 2, 10);

    const accuracy =
      this.bubblesPopped === 0
        ? 0
        : (this.bubblesPopped - this.attentionMistakes) / this.bubblesPopped;
    const attentionConcentrationScore = (accuracy * 10).toFixed(1);
    console.log(
      'aaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
      this.totalDecisions,
      this.decisionMakingPoints
    );
    const decisionMakingScore =
      this.totalDecisions === 0
        ? 0
        : (this.decisionMakingPoints / this.totalDecisions) * 10;
    // Navigate back to '/' route before opening the dialog
    this.router.navigate(['/']);
    console.log(decisionMakingScore);
    // Open the game results dialog
    const dialogRef = this.dialog.open(GameResultsDialogComponent, {
      width: '500px',
      data: {
        handEyeCoordinationScore: handEyeCoordinationScore,
        averageReactionTime: averageReactionTime.toFixed(2),
        reactionTimeScore: reactionTimeScore.toFixed(1),
        attentionConcentrationScore: attentionConcentrationScore,
        decisionMakingScore: decisionMakingScore.toFixed(1),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(['/bubble-game']);
    });

    // this.animateResults();
  }

  // animateResults() {
  //   const results = this.resultsDiv.querySelectorAll('.result');
  //   results.forEach((result: any, index: number) => {
  //     result.style.animation = `fadeInUp 1s forwards ${index * 0.5}s`;
  //   });
  //   const playAgainButton = document.getElementById('playAgainButton');
  //   if (playAgainButton) {
  //     playAgainButton.addEventListener('click', () => {
  //       // Use Angular's router to navigate
  //       this.router.navigateByUrl('/balloon-burst');
  //     });
  //   }
  // }

  clearGame() {
    clearInterval(this.bubbleInterval);
    clearTimeout(this.gameTimer);
  }

  handleClick(event: any) {
    if (!event.target.classList.contains('bubble')) {
      this.attentionMistakes++;
    }
  }
}
